.processMetaData{
    width: 52%;
}

.editButton{
    border: 1px solid black;

}

.process-button{
    font-size: 1.4rem;
    width: 90px;
    height: 35px;
}

.header-margin{
    margin-right: 10px;
}

.processes-container{
    width: 65%;
}

.processview-container{
    width: 85%;
}

.process-view-shadow{
    box-shadow: 1px 0px 4px rgb(179, 177, 177);
}

.processview-admin-grey-row{
    background-color: rgb(183, 180, 180);
}

.processview-new-admin-text{
    color:rgb(50, 106, 246);
    font-style: italic;
}

.processview-admin-grey-text{
    color:grey
}

.attribute-values-dropdown{
    width: 100%;
}

.attribute-name-btn{
    font-size: 1.5rem;
    width: 18rem;
}

.add-attribute-values-btn{
    height: 3.5rem;
}

@media only screen and (max-width:1200px){
    .add-attribute-values-btn{
        height: auto;
        width: 10rem;
    }
}

.attribute-dropdown-open-btn{
    width: 5rem;
}

.attribute-dropdown-toggle{
    width: 30rem;
    height: 4rem;
    font-size: 1.5rem;
}

@media only screen and (max-width:1200px){
    .attribute-dropdown-toggle{
        width: 20rem;
        height: 4rem;
        font-size: 1.5rem;
    }
}

.attribute-dropdown-toggle::after{
    display: none;
}

.attribute-dropdown-arrow{
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.attribute-dropdown-arrow.open{
    transform: rotate(180deg);
}

.attribute-dropdown-menu{
    max-height: 20rem;
    width: 35rem;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-gutter: stable;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

@media only screen and (max-width:1200px){
    .attribute-dropdown-menu{
        max-height: 20rem;
        width: 25rem;
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-gutter: stable;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
    }
}

.attribute-name-placeholder{
    color:rgb(183, 180, 180);
}

.attribute-name-input{
    background-color: #f8f9fa;
    font-size: 1.5rem;
}

.attribute-dropdown-input{
    background-color: #f8f9fa;
}

.attribute-dropdown-divide{
    color: #a9abad;
}

.attribute-menu-placeholder{
    height: 0rem;
    transition: height 0.3s ease;
}

.attribute-menu-placeholder.open{
    --dynamic-menu-height: 14rem;
    height: var(--dynamic-menu-height);
}

.attribute-value-text{
    font-size: 1.3rem;
}

.attribute-value-adding-input{
    font-size: 1.3rem;
}

.attribute-new-item-text{
    color: rgb(50, 106, 246);
    font-style: italic;
}

.sort-order-table{
    width: 50%;
    max-height: 50rem;
    overflow: auto;
}

.sort-order-table-container{
    font-size: 2rem;
}

.sort-order-table-divider{
    border-top: 1px solid rgb(207, 204, 204);
}

.sort-order-table-close-btn{
    width: 5rem;
    transform: translateY(-1.5rem);
}

.sort-order-table-header{
    font-size: 1.3rem;
}

.sort-order-table-cell{
    font-size: 1.3rem;
}

.sort-order-table-new-cell{
    font-size: 1.3rem;
    font-style: italic;
    color: rgb(50, 106, 246);
}

.btn.exitButton[disabled] {
    background-color: rgb(183, 180, 180);
}

.exitButton {
    width:90px;
    height:30px;
}
