.reset-button{
    width: 50px;
    font-size: 12px;
}
.select-all-button {
    font-size: 12px;
}
.create-keys-multi-select-filter-wrapper {
    width: 25%;
}
.create-keys-multi-select-label-wrapper {
    width: 35%;
    justify-content: flex-end !important;
}
.create-keys-multi-select-reset-wrapper, .create-keys-single-select-reset-wrapper {
   width: 30%;
}
.create-keys-multi-select-reset-wrapper > button, .create-keys-single-select-reset-wrapper > button,
    .create-keys-multi-select-reset-wrapper > button:focus, .create-keys-single-select-reset-wrapper > button:focus {
    background-color: rgb(234, 237, 237);
    border-color: rgb(234, 237, 237);
    color: black;
 }
 .create-keys-multi-select-reset-wrapper > button:hover, .create-keys-single-select-reset-wrapper > button:hover {
    background-color: lightgray;
    border-color: lightgray;
 }
.create-keys-single-select-filter-wrapper {
    width: 25%;
}
.create-keys-single-select-label-wrapper {
    width: 35%;
    justify-content: flex-end !important;
}
.create-keys-multi-select-filter-wrapper > div > div:nth-child(2) {
    max-height: 12.5rem;
    overflow: hidden;
    overflow-wrap: break-word;
    margin-top: 0.5rem;
    padding: 0.5rem;
}
.create-keys-multi-select-filter-wrapper > div > div:nth-child(2):hover {
    overflow: auto;
}