.create-keys-container {
    padding-bottom: 1.5rem;
}
.editButton{
    border: 1px solid black;
}
.keys-assignee-section-div{
    width: 86%;
}
.keys-delegate-section-div{
    width:86%
}
.keysButton{
    width:90px;
    height:30px;
}
.property-label{
    width:5%;
    text-align:right;
    font-weight: bold;
}
.delegate-property-label{
    width:4%;
    text-align:right;
    font-weight: bold;
}
.value-label{
    width:12%;
    text-align: left;

}
.btn-div{
    width:15%;
}
.email-label{
    width:20%
}
.draft-name-wrapper {
    align-items: baseline;
}
.name-label{
    width:20%
}
.name-label-field  {
    margin-right: 2rem;
}
.draft-error-wrapper {
    width: 30%;
}
.delete-draft-title-wrapper {
    text-align: center;
}
.existing-draft-name-input-wrapper {
    width: 25%;
}
.existing-draft-name-input-wrapper > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.existing-draft-name-label-wrapper {
    width: 35%;
    justify-content: flex-end;
}
.existing-draft-name-error-wrapper {
    width: 30%;
    align-self: flex-start;
}
.draft-name-label-wrapper {
    width: 40%;
    justify-content: flex-end;
}
.draft-name-error-wrapper {
    width: auto;
    height: 2rem;
}
.draft-name-input-wrapper {
    width: 60%;
}
.reset-all-button-wrapper, .cancel-button-wrapper, .delete-draft-wrapper, 
    .reset-all-button-wrapper:focus, .cancel-button-wrapper:focus, .delete-draft-wrapper:focus {
    background-color: rgb(234, 237, 237);
    border-color: rgb(234, 237, 237);
    color: black;
    font-size: 12px;
}
.reset-all-button-wrapper:hover, .cancel-button-wrapper:hover, .delete-draft-wrapper:hover {
    background-color: lightgray;
    border-color: lightgray;
    color: #fff;
}