.additional-info-main-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.additional-info-notes-wrapper {
    max-width: 20rem !important;
}

.additional-info-notes-wrapper > textarea {
    max-height: 15rem !important;
    min-height: 5rem !important;
}

.additional-info-labels > div {
    text-align: center;
}
