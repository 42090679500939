.menu-items {
    font-size: 14px;
}

.nav-dropdown {
    padding: .5rem 1rem;
}

.navbar-title {
    font-size: 18px;
}

.navbar-dropdown {
    width: 15%;
}

.navbar-margin {
    margin-left: 15px;
}
/* Turn User Help dropdown same color as other text in navbar */
div.dropdown.nav-item > a {
    color: rgba(255, 255, 255, 0.55);
}
/* Turn User Help dropdown same color as other text in navbar when hovering*/
div.dropdown.nav-item:hover > a {
    color: rgba(255, 255, 255, 0.75);
}

.dropdown-item {
    font-size: 14px;
}

.message-bar-text{
    font-size: 1.3rem;
}